import React, { FC } from 'react';
import cn from 'classnames';
import Label from 'components/UI/Input/Label';
import { useTranslation } from 'react-i18next';
import Button from 'components/UI/Buttons/buttons';
import { MdInfoOutline } from 'react-icons/md';
import ErrorDictionary from 'components/Errors/ErrorDictionary';
import { FetchedDataErrors } from 'types/general';
import { toolTipType } from 'components/UI/Input/types';
import input from '../../../styles.module.scss';

interface IVideoLinkBodyProps {
  label?: string;
  error: FetchedDataErrors;
  /** Режим редактирования */
  edit?: boolean;
  /** Вывод тултипа со ссылкой */
  tooltip?: toolTipType;
  /** Включить Редактирование */
  hasEdit?: boolean;
  /** Предупреждающий текст */
  hintText?: React.ReactNode | string;
}

const VideoLinkBody: FC<IVideoLinkBodyProps> = ({
  label,
  error,
  edit,
  tooltip,
  hasEdit,
  hintText,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={cn(input.link, { _isError: error.isError })}>
        {label && (
          <Label
            label={t(`campaigns_page.campaign_category.Video`)}
            tooltip={tooltip}
            forInput="#"
          />
        )}

        <div className={input.row}>
          <input
            type="text"
            autoComplete="off"
            value=""
            // onChange={async (event) => {
            //   setUrl(event.target.value);
            //   await validateForm();
            // }}
            disabled={hasEdit && !edit}
          />

          <Button
            height={40}
            title={t('add_btn')}
            autoWidth
            buttonAttributes={{
              style: {
                height: 40,
              },
              //   onClick: () => {
              //     handlerUploadFileLink();
              //   },
              //   disabled: !edit || !url,
            }}
          />
        </div>
      </div>

      {hintText && !error.isError && (
        <span className={input.hintText}>
          <MdInfoOutline size="20" />
          {hintText}
        </span>
      )}
      {error.isError && (
        <span className={input.errorText}>
          <MdInfoOutline size="20" />
          <ErrorDictionary code={error.code === 2034 ? 20340 : error.code} />
          {error.code === 200032 || error.code === 2032}
          {error.code === 2034 || error.code === 20340}
        </span>
      )}
    </>
  );
};

export default VideoLinkBody;
