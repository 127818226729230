import React, { FC, useRef } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import StatisticsTable from 'components/StatisticsTable';
import { connect } from 'react-redux';
import { FiDelete, FiTrash2 } from 'react-icons/fi';
import Load from 'components/UI/Load';
import ModalSettings from 'components/ModalSettings';
import { ReactSVG } from 'react-svg';
import ElementsNone from 'components/ElementsNone';
import useSearchField from 'hooks/useSearchField';
import { KeysSettings, useStateValue } from 'utils/context';
import { formatStatistics } from 'domains/campaigns/model/utils/formatStatistics';
import { useSearchBreadcrumbsInfo } from 'domains/search/model/selectors';
import { AiOutlineEdit } from 'react-icons/ai';
import Header from './Header';
import {
  ElementsNoneType,
  TDates,
  TPropsSelector,
} from '../../../types/general';
import { useCreativeList } from './hooks/useCreativesList';
import ErrorReport from '../../../components/Errors/Reports';
import css from './styles.module.scss';
import List from './List';
import {
  selectCreativeView,
  useCreativeListInfo,
  useCreativeStatisticsListInfo,
  useCreativesTranslation,
} from '../model/selectors';
import { CreativeItemListWithStatistics } from '../types';
import OnlyOwner from '../../../components/OnlyOwner';
import ModalWithQuestion from '../../modals/modalWithQuestion';
import ToggleSwitch from '../../../components/UI/ToggleSwitch';
import CopyCreativeModal from './CopyCreativeModal';
import CopySvg from '../../../assets/copy-icon.svg';
import DuplicateSvg from '../../../assets/duplicate-icon.svg';
import { useFilterCreoListByTranslation } from './hooks/useFilterCreoListByTranslation';
import emptyTranslationResult from '../../../assets/errors/empty-creo-translation.svg';
import emptyFilterResult from '../../../assets/errors/emptyFilterResult.svg';
import MassEdit from '../ui/MassEdit';
import LoadingStatisticsTableHeader from './components/LoadingStatisticsTableHeader';

type Props = TPropsSelector<CreativeItemListWithStatistics[]>;

const Creatives: FC<Props> = ({
  list,
  isLoading,
  isLoadingView,
  LTU,
  serverList,
  error,
}) => {
  const { t } = useTranslation();

  const { data: creoList, isLoading: isLoadingCreoList } =
    useCreativeListInfo();

  const { data: creoTranslationData, isLoading: isLoadingCreoTranslation } =
    useCreativesTranslation();

  const { data: bread_crumbs } = useSearchBreadcrumbsInfo();

  const { state } = useStateValue();

  const { period } = state.settings[KeysSettings.CAMPAIGN] as TDates;

  const searchRef = useRef<HTMLInputElement>(null);
  const searchObj = useSearchField(searchRef);

  const { currentFilter, currentTranslationXxhashArr, filterCreoTranslation } =
    useFilterCreoListByTranslation(
      creoList,
      creoTranslationData,
      'creo',
      searchObj.search,
      period,
    );

  const {
    sumStatistics,
    lastId,
    match,
    colClass,
    listFilter,
    toggle,
    setToggle,
    handlerCloneCreative,
    cloneResponse,
    cloneResult,
    openCopyModal,
    setOpenCopyModal,
    openCleanModal,
    setOpenCleanModal,
    handlerRequestDelete,
    handlerRequestCleanAllTargets,
    openDeleteModal,
    setOpenDeleteModal,
    handlerChangeStatus,
    copyCreative,
    isTop,
    isThemeExtended,
    isThemeFull,
    keysFields,
    setCloneResult,
    setCloneResponse,
    isShowEmpty,
    onFilterToggleDropdown,
    setIsModalAllStatus,
    isModalAllStatus,
    campaign,
    creativeCheckedList,
    handlerSetCreativeInCheckedList,
    allCreativeChecked,
    allPartialCreativeChecked,
    handlerAllCheckedCreatives,
    handlerClickStatusCreativesEdit,
    generateTextIsModalText,
    handlerCreativesUpdatedStatus,
    isCopyCreativeModal,
    setIsCopyCreativeModal,
    setCreativeCheckedList,
    isLoadingSearch,
    isMassEditModal,
    setIsMassEditModal,
    currentCheckedItems,
  } = useCreativeList({
    serverList,
    LTU,
    list,
    isLoadingView,
    isLoading,
    error,
    search: searchObj.search,
    currentFilter,
    currentTranslationXxhashArr,
  });

  const { isLoading: isLoadingStat } = useCreativeStatisticsListInfo();

  /*   const [isSaveMassEditData, setIsSaveMassEditData] = useState<MassEditLinkParams | null>(null); */

  if (error && error.isError) {
    return <ErrorReport error={error} />;
  }

  return (
    <div className={css.creatives}>
      <Helmet>
        <title>
          {t('campaigns_page.campaign_settings.black_menu.Creatives')}
        </title>
      </Helmet>

      <Header
        isThemeExtended={isThemeExtended}
        isThemeFull={isThemeFull}
        searchMatch={list.length}
        searchRef={searchRef}
        searchObj={searchObj}
        isShowMatch={!isLoadingSearch && !isLoading}
        currentTranslationXxhashArr={currentTranslationXxhashArr}
      />

      {!isLoadingView &&
      !isLoading &&
      list.length === 0 &&
      currentFilter === 'isAll' &&
      listFilter === '' ? (
        <ElementsNone
          type={ElementsNoneType.CREATIVE}
          search={list.length === 0 && serverList && serverList.length > 0}
        />
      ) : (
        <>
          {isLoading || isLoadingView || isLoadingSearch ? (
            <LoadingStatisticsTableHeader />
          ) : (
            <>
              <StatisticsTable
                filterDataLength={list.length}
                colClass={colClass}
                header={[
                  t('creative.tables_type'),
                  t('creative.tables_name'),
                  ...keysFields.map(({ title }) => title),
                ]}
                sumStatistics={sumStatistics}
                sumTitle={
                  <span className="text">{t('creative.allstatrk')}</span>
                }
                isThemeExtended={isThemeExtended}
                isThemeFull={isThemeFull}
                keysFields={keysFields}
                onFilterToggleDropdown={onFilterToggleDropdown}
                allCreativeChecked={allCreativeChecked}
                allPartialCreativeChecked={allPartialCreativeChecked}
                handlerAllCheckedCreatives={handlerAllCheckedCreatives}
                filterCreoTranslation={filterCreoTranslation}
                currentFilter={currentFilter}
                isLoadingTranslationFilter={
                  isLoadingCreoTranslation || isLoadingCreoList
                }
              >
                <div
                  className={cn(css.campstat, 'row', {
                    _isFullTitle: isThemeExtended || isThemeFull,
                    isTop,
                  })}
                >
                  <div className={cn(css.fixed, 'fixed')}>
                    <div className={css.text}>{t('creative.allstatrk')}</div>
                  </div>
                  {!!list.length &&
                    keysFields.map(({ key, title, isLengthTitle }) => (
                      <div
                        className={cn(
                          css.item,
                          colClass[key] !== undefined
                            ? colClass[key]
                            : `size-${isLengthTitle}`,
                          {
                            _isGrey: title.trim().toLocaleLowerCase() === 'bid',
                          },
                        )}
                        key={key}
                      >
                        {!isLoadingStat && sumStatistics ? (
                          formatStatistics(sumStatistics[key], true)
                        ) : (
                          <Load />
                        )}
                      </div>
                    ))}
                  <div className="options" />
                </div>
                <List
                  isShowEmpty={isShowEmpty}
                  lastId={lastId}
                  isLoading={isLoading || !!isLoadingView}
                  creativeListView={list}
                  colClass={colClass}
                  keysFields={keysFields}
                  toggle={toggle}
                  setToggle={setToggle}
                  handlerCloneCreative={handlerCloneCreative}
                  cloneResult={cloneResult}
                  setCloneResponse={setCloneResponse}
                  cloneResponse={cloneResponse}
                  openCopyModal={openCopyModal}
                  setOpenCopyModal={setOpenCopyModal}
                  openDeleteModal={openDeleteModal}
                  setOpenDeleteModal={setOpenDeleteModal}
                  openCleanModal={openCleanModal}
                  setOpenCleanModal={setOpenCleanModal}
                  handlerRequestDelete={handlerRequestDelete}
                  handlerRequestCleanAllTargets={handlerRequestCleanAllTargets}
                  handlerChangeStatus={handlerChangeStatus}
                  copyCreative={copyCreative}
                  isThemeExtended={isThemeExtended}
                  isThemeFull={isThemeFull}
                  match={match}
                  setCloneResult={setCloneResult}
                  creativeCheckedList={creativeCheckedList}
                  handlerSetCreativeInCheckedList={
                    handlerSetCreativeInCheckedList
                  }
                />
              </StatisticsTable>
              {list.length === 0 && currentFilter !== 'isAll' && (
                <ElementsNone
                  customImg={emptyTranslationResult}
                  customTitle={t(`translations.no_results_status`)}
                />
              )}
              {list.length === 0 &&
                listFilter !== '' &&
                currentFilter === 'isAll' && (
                  <ElementsNone
                    customImg={emptyFilterResult}
                    customTitle={t(`creative.no_result_for_filter`)}
                  />
                )}
            </>
          )}
        </>
      )}

      {!!creativeCheckedList.length && (
        <ModalSettings closeOutside={isModalAllStatus}>
          <div
            className={css.settings__item}
            onClick={() => handlerClickStatusCreativesEdit('LAUNCHED')}
          >
            <ToggleSwitch
              checked
              oneText={t('start')}
              mini
            />
          </div>
          <div
            className={css.settings__item}
            onClick={() => handlerClickStatusCreativesEdit('STOPPED')}
          >
            <ToggleSwitch
              checked={false}
              oneText={t('stop')}
              mini
            />
          </div>
          <div
            className={css.settings__item}
            onClick={() => setIsMassEditModal(true)}
          >
            <AiOutlineEdit size={20} />
            {t(`creative.mass_editing`)}
          </div>
          <div
            className={css.settings__item}
            onClick={() => handlerClickStatusCreativesEdit('DELETED')}
          >
            <FiTrash2 size={18} />
            {t(`delete_btn`)}
          </div>
          <div
            className={css.settings__item}
            onClick={() => handlerClickStatusCreativesEdit('DUPLICATE')}
          >
            <ReactSVG src={DuplicateSvg} />
            {t(`duplicate_btn`)}
          </div>
          <div
            className={css.settings__item}
            onClick={() => setIsCopyCreativeModal(true)}
          >
            <ReactSVG className="icon" src={CopySvg} />
            {t(`copy_to_another_campaign_btn`)}
          </div>
          <div
            className={css.settings__item}
            onClick={() => handlerClickStatusCreativesEdit('CLEAR_TARGET')}
          >
            <FiDelete size={24} />
            {t(`creative.cleartarg`)}
          </div>
        </ModalSettings>
      )}

      {isModalAllStatus && campaign && (
        <OnlyOwner>
          <ModalWithQuestion
            onClose={() => setIsModalAllStatus(false)}
            onClick={handlerCreativesUpdatedStatus}
            subText={generateTextIsModalText.text}
            title={campaign.title}
            btnTitle={generateTextIsModalText.button}
            modalName={t('just_words.confirm_action')}
          />
        </OnlyOwner>
      )}
      {isCopyCreativeModal && campaign && (
        <CopyCreativeModal
          onClose={() => setIsCopyCreativeModal(false)}
          modalProps={{
            partner_id: bread_crumbs?.Client?.xxhash || '',
            campaign_id: campaign.xxhash,
            id: creativeCheckedList,
            title: campaign.title,
            callback: () => {
              setCreativeCheckedList([]);
            },
          }}
          isOpen={isCopyCreativeModal}
        />
      )}
      <MassEdit
        creativeCheckedList={creativeCheckedList}
        currentCheckedItems={currentCheckedItems}
        isMassEditModal={isMassEditModal}
        setIsMassEditModal={setIsMassEditModal}
        setCreativeCheckedList={setCreativeCheckedList}
      />
    </div>
  );
};
export default connect(selectCreativeView)(Creatives);
