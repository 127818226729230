import React, { ChangeEvent, FC, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Form, Field, FormikProvider, FieldProps } from 'formik';
import { useTranslation } from 'react-i18next';
import Input from 'components/UI/Input/input';
import ToggleSwitch from 'components/UI/ToggleSwitch';
import RadioGroup from 'components/UI/RadioGroup';
import { ProfileForm, useSettingsProfile } from 'domains/profile/hook';
import ErrorText from 'components/UI/ErrorText';
import Card from 'components/UI/Card';
import Button from 'components/UI/Buttons/buttons';
import InputWithPass from 'components/InputWithPass';
import NewSelect from 'components/UI/NewSelect';
import { ReactComponent as NormalSvg } from './icons/normal.svg';
import { ReactComponent as ExtendedSvg } from './icons/extended.svg';
import { ReactComponent as FullSvg } from './icons/full.svg';

import css from './styles.module.scss';

const Settings: FC = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const {
    formik,
    onSwitchNotification,
    onSwitchNavigationInCompaign,
    onSwitchNavigationInCreative,
    onSwitchGroupsLimits,
    onSwitcHideHelp,
    onSwitchAnimated,
    onSwitchOffers,
    onSwitchAppNotification,
    onSwitchHintInCompaign,
    onSwitchBroadcast,
    langData,
    defaultLang,
    isErrorBrowserNotification,
    handleEditPassword,
  } = useSettingsProfile(currentTab);

  const { t } = useTranslation();

  return (
    <FormikProvider value={formik}>
      <Form className={css.settings}>
        <Card className={css.card}>
          <h3 className={css.title}>{t('profile.settings_tit')}</h3>
          <div className={css.form}>
            <Tabs
              selectedIndex={currentTab}
              onSelect={(index) => setCurrentTab(index)}
              className="vertical_tabs"
            >
              <TabList className="vertical_tabs__tab-list">
                <Tab className="vertical_tabs__tab">
                  <span>{t('profile.main')}</span>
                </Tab>
                <Tab className="vertical_tabs__tab">
                  <span>{t('profile.safety.title')}</span>
                </Tab>
                {(formik.values.notification !== undefined ||
                  formik.values.app_notification !== undefined) && (
                  <Tab className="vertical_tabs__tab">
                    <span>{t('profile.push.title')}</span>
                  </Tab>
                )}
                {(formik.values.theme !== undefined ||
                  formik.values.animation !== undefined ||
                  formik.values.HideNavigationCampaign !== undefined ||
                  formik.values.HideNavigationCreative !== undefined ||
                  formik.values.groups_limits !== undefined) && (
                  <Tab className="vertical_tabs__tab">
                    <span>{t('profile.view.title')}</span>
                  </Tab>
                )}
                {(formik.values.ShowBroadcastStatus !== undefined ||
                  formik.values.ShowHint !== undefined) && (
                  <Tab className="vertical_tabs__tab">
                    <span>{t('translations.title_widget')}</span>
                  </Tab>
                )}
              </TabList>
              <TabPanel className="vertical_tabs__tab-panel">
                <div className={css.main}>
                  <Input
                    label="E-Mail"
                    disabled
                    notField={false}
                    inputAttributes={{
                      type: 'text',
                      name: 'email',
                      value: `${formik.values.email}`,
                      placeholder: '',
                    }}
                  />
                  <Field name="lang">
                    {(formProps: FieldProps) => (
                      <NewSelect
                        className={css.lang}
                        label={t('login_page.language_tit')}
                        form={formProps.form}
                        field={formProps.field}
                        onCustomChange={() => formik.handleSubmit()}
                        selectAttributes={{
                          value: defaultLang(formik.values.lang),
                          options: langData,
                        }}
                        viewStyle="Vertical"
                      />
                    )}
                  </Field>
                </div>
              </TabPanel>
              <TabPanel className="vertical_tabs__tab-panel">
                <div className={css.safety}>
                  <div className={css.label}>
                    {t('profile.safety.subtitle')}
                  </div>
                  <div className={css.items}>
                    {['old_password', 'new_password', 'confirm_password'].map(
                      (keyPass: string) => (
                        <InputWithPass
                          key={keyPass}
                          label={t(`profile.safety.${keyPass}`)}
                          error={
                            formik.touched.passwordData &&
                            !!formik.touched.passwordData[
                              keyPass as keyof ProfileForm['passwordData']
                            ] &&
                            formik.errors.passwordData &&
                            !!formik.errors.passwordData[
                              keyPass as keyof ProfileForm['passwordData']
                            ]
                          }
                          errortext={
                            formik.errors.passwordData &&
                            formik.errors.passwordData[
                              keyPass as keyof ProfileForm['passwordData']
                            ]
                          }
                          inputAttributes={{
                            name: `passwordData.${keyPass}`,
                            value:
                              formik.values.passwordData[
                                keyPass as keyof ProfileForm['passwordData']
                              ],
                            onChange: (e: ChangeEvent<HTMLInputElement>) => {
                              formik.setFieldTouched(`passwordData.${keyPass}`);
                              formik.handleChange(e);
                            },
                          }}
                        />
                      ),
                    )}
                    <Button
                      buttonAttributes={{
                        onClick: handleEditPassword,
                        disabled:
                          !formik.isValid ||
                          !formik.values.passwordData.old_password ||
                          !formik.values.passwordData.confirm_password ||
                          !formik.values.passwordData.new_password,
                      }}
                      title={t('form.common.saveChanges')}
                    />
                  </div>
                </div>
              </TabPanel>
              {(formik.values.notification !== undefined ||
                formik.values.app_notification !== undefined) && (
                <TabPanel
                  className="vertical_tabs__tab-panel"
                  style={{
                    padding: 0,
                  }}
                >
                  <div className={css.push}>
                    {formik.values.notification !== undefined && (
                      <ToggleSwitch
                        onChange={onSwitchNotification}
                        checked={!!formik.values.notification}
                        disabled={isErrorBrowserNotification}
                        activateText={t('profile.push.toggle_active')}
                        deactivateText={t('profile.push.toggle_inactive')}
                      />
                    )}
                    {formik.values.app_notification !== undefined && (
                      <ToggleSwitch
                        onChange={onSwitchAppNotification}
                        checked={!!formik.values.app_notification}
                        activateText={t('profile.app_notification_on')}
                        deactivateText={t('profile.app_notification_off')}
                      />
                    )}
                  </div>
                  {isErrorBrowserNotification &&
                    formik.values.notification !== undefined && (
                      <div>
                        <ErrorText
                          text={
                            <>
                              {t(
                                'campaigns_page.campaign_settings.push_notifications.browser_push_error_1',
                              )}{' '}
                              <br />
                              {t(
                                'campaigns_page.campaign_settings.push_notifications.browser_push_error_2',
                              )}
                            </>
                          }
                        />
                      </div>
                    )}
                </TabPanel>
              )}
              {(formik.values.theme !== undefined ||
                formik.values.animation !== undefined ||
                formik.values.HideNavigationCampaign !== undefined ||
                formik.values.HideNavigationCreative !== undefined) && (
                <TabPanel
                  className="vertical_tabs__tab-panel"
                  style={{
                    padding: 0,
                  }}
                >
                  <div className={css.appearance}>
                    {formik.values.theme !== undefined && (
                      <div className={css.tables}>
                        <div className={css.label}>
                          {t('profile.view.theme_subtitle')}
                        </div>
                        <div className={css.miniatures}>
                          <NormalSvg />
                          <ExtendedSvg />
                          <FullSvg />
                        </div>
                        <RadioGroup
                          name="theme"
                          label=""
                          options={[
                            {
                              title: t('profile.view.noraml_view'),
                              value: 'NORMAL',
                            },
                            {
                              title: t('profile.view.extended_view'),
                              value: 'EXTENDED',
                            },
                            {
                              title: t('profile.view.super_extended_view'),
                              value: 'SUPER_EXTENDED',
                            },
                          ]}
                          edit
                          autoSubmit
                          setFieldValue={formik.setFieldValue}
                          handleSubmit={formik.handleSubmit}
                          checked={formik.values.theme}
                          className={css.buttons}
                        />
                      </div>
                    )}
                    {formik.values.animation !== undefined && (
                      <div className={css.item}>
                        <div className={css.label}>
                          {t('profile.view.toggle_animate')}
                        </div>
                        <ToggleSwitch
                          onChange={onSwitchAnimated}
                          checked={!!formik.values.animation}
                          oneText={t('profile.view.animate_info')}
                        />
                      </div>
                    )}
                    {(formik.values.offer !== null &&
                      formik.values.offer !== undefined) && (
                      <div className={css.item}>
                        <div className={css.label}>
                          {t('offers_page.offers')}
                        </div>
                        <ToggleSwitch
                          onChange={onSwitchOffers}
                          checked={!!formik.values.offer}
                          oneText={
                            <>
                              {t(`profile.offer`)}
                              <br />
                              <a
                                href="https://terratraf.readme.io/docs/td-general-offers"
                                target="_blank"
                                rel="noreferrer"
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                {t(`profile.show_more`)}
                              </a>
                            </>
                          }
                        />
                      </div>
                    )}
                    {formik.values.HideNavigationCampaign !== undefined && (
                      <div className={css.item}>
                        <div className={css.label}>
                          {t('profile.view.hide_navigation_campaign')}
                        </div>
                        <ToggleSwitch
                          onChange={onSwitchNavigationInCompaign}
                          checked={!!formik.values.HideNavigationCampaign}
                          oneText={t('profile.view.navigation_info_comp')}
                        />
                      </div>
                    )}
                    {formik.values.HideNavigationCreative !== undefined && (
                      <div className={css.item}>
                        <div className={css.label}>
                          {t('profile.view.hide_navigation_creative')}
                        </div>
                        <ToggleSwitch
                          onChange={onSwitchNavigationInCreative}
                          checked={!!formik.values.HideNavigationCreative}
                          oneText={t('profile.view.navigation_info_creo')}
                        />
                      </div>
                    )}
                    {formik.values.groups_limits !== undefined && (
                      <div className={css.item}>
                        <div className={css.label}>
                          {t('clients_page.limit_groups.show_groups')}
                        </div>
                        <ToggleSwitch
                          onChange={onSwitchGroupsLimits}
                          checked={!!formik.values.groups_limits}
                          oneText={t(
                            'clients_page.limit_groups.show_groups_hints',
                          )}
                        />
                      </div>
                    )}
                    {formik.values.HideHelp !== undefined && (
                      <div className={css.item}>
                        <div className={css.label}>
                          {t('profile.view.hide_hints')}
                        </div>
                        <ToggleSwitch
                          onChange={onSwitcHideHelp}
                          checked={!!formik.values.HideHelp}
                          oneText={t('profile.view.hide_hints_add')}
                        />
                      </div>
                    )}
                  </div>
                </TabPanel>
              )}
              {(formik.values.ShowBroadcastStatus !== undefined ||
                formik.values.ShowHint !== undefined) && (
                <TabPanel
                  className="vertical_tabs__tab-panel"
                  style={{
                    padding: 0,
                  }}
                >
                  <div className={css.status_translation}>
                    {formik.values.ShowBroadcastStatus !== undefined && (
                      <div className={css.item}>
                        <div className={css.label}>
                          {t('translations.profile_settings')}
                        </div>
                        <ToggleSwitch
                          onChange={onSwitchBroadcast}
                          checked={!!formik.values.ShowBroadcastStatus}
                          oneText={t('translations.profile_set_info')}
                        />
                      </div>
                    )}
                    {formik.values.ShowHint !== undefined && (
                      <div className={css.item}>
                        <div className={css.label}>
                          {t('profile.view.show_prompt_in_form')}
                        </div>
                        <ToggleSwitch
                          onChange={onSwitchHintInCompaign}
                          checked={!!formik.values.ShowHint}
                          oneText={t(
                            'profile.view.show_prompt_in_form_info',
                          )}
                        />
                      </div>
                    )}
                  </div>
                </TabPanel>
              )}
            </Tabs>
          </div>
          {/* <Button
            title={t('form.common.saveChanges')}
            lightblue
            icon={<FiSave size={20} />}
            buttonAttributes={{
              className: css.save,
            }}
          /> */}
        </Card>
      </Form>
    </FormikProvider>
  );
};

export default Settings;
