import React, { FC } from 'react';
import cn from 'classnames';
import { FiExternalLink } from 'react-icons/fi';
import input from '../../../styles.module.scss';
import vast_iconSvg from '../../../../../../assets/vast_icon.svg';

interface IVideoLinkPreviewProps {
  file_url: string;
  client_name: string;
}

const VideoLinkPreview: FC<IVideoLinkPreviewProps> = ({
  file_url,
  client_name,
}) => (
  <>
    <div
      className={cn(input.image, 'video_plug' ,{ isLarge: false })}
      style={{
        backgroundImage: `url('${vast_iconSvg}')`,
      }}
    />
    <div className={input.description}>
      <a
        href={`${file_url}`}
        target="_blank"
        rel="noopener noreferrer"
        className={input.filename}
      >
        <span>{client_name}</span>
        <FiExternalLink size={16} color="#025DFF" />
      </a>
    </div>
  </>
);

export default VideoLinkPreview;
